// React
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

// Material UI Icons
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileOpenIcon from "@mui/icons-material/FileOpen";

// Components
import { Puller } from "ui-components/Puller";

// Metadata schema
import { metadataSchema } from "./metadataSchema";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDNFileDetailsDialog = ({ open, onClose, record }) => {
  console.log(record);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [xmlContent, setXmlContent] = useState("");
  const [textContent, setTextContent] = useState("");

  const fields = useMemo(() => {
    const customFields = [
      {
        label: t("generation_datetime"),
        value: convertTimestamp(record.timestamp),
      },
    ];

    const schemaFields = Object.keys(metadataSchema).map((key) => ({
      label: metadataSchema[key].label,
      value: record[key],
    }));

    return [...customFields, ...schemaFields];
  }, [record]);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, onClose]);

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType === "image/png") {
        setSelectedFile(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error);
    }
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const DetailsForm = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {t("id")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {record.id || "N/A"}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {t("status")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {t("uploaded")}
          </Typography>
        </Typography>
      </Grid>
      {fields.map((field, index) =>
        field.value ? (
          <Grid item xs={12} sm={12} md={6} key={index}>
            <Typography variant="body1" fontWeight="bold">
              {field.label}
              <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                {field.value}
              </Typography>
            </Typography>
          </Grid>
        ) : null
      )}
      <Grid item xs={12}>
        <Button variant="contained" fullWidth startIcon={<FileOpenIcon />} onClick={() => handleFileClick(record.data.attachment)}>
          {t("open_file")}
        </Button>
      </Grid>
    </Grid>
  );

  const renderFileDialog = () => {
    switch (record.fileType) {
      case "application/pdf":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        const rows = textContent.split("\n").map((row) => row.split(","));
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <table>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Dialog>
        );

      case "image/png":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ position: "relative" }}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "2%",
                  right: "2%",
                  color: "red",
                }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
              <img src={selectedFile} alt="Document" style={{ width: "100%" }} />
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: "5%" }}>
            <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("details")}
                </Typography>
              </Grid>
            </Grid>
            {DetailsForm}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("details")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose} edge="end" sx={{ color: "red" }} aria-label={t("close")}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{DetailsForm}</DialogContent>
        </Dialog>
      )}

      {renderFileDialog()}
    </>
  );
};

CDNFileDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNFileDetailsDialog;
