// React
import React, { useEffect, useState } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import addViewGroup from "tag/addViewGroup";
import addViewGroupReadOnly from "tag/addViewGroupReadOnly";
import fetchUserGroups from "GroupOperations/fetchUserGroups";
import getViewGroups from "tag/getViewGroups";
import getViewGroupsReadOnly from "tag/getViewGroupsReadOnly";

// A ---------------------------------------------------------------------- M

const AddViewGroup = ({ tag, open, setOpen }) => {
  const { user, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [groups, setGroups] = useState();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [permissions, setPermissions] = useState("r");
  const [groupAlreadyAdded, setGroupAlreadyAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      const fetchGroups = async () => {
        setIsLoading(true);

        try {
          const userGroups = await fetchUserGroups(user.uid);
          const viewGroups = await getViewGroups(tag.id);
          const viewGroupReadOnly = await getViewGroupsReadOnly(tag.id);

          const viewGroupIds = viewGroups.map((group) => group.id);
          const viewGroupReadOnlyIds = viewGroupReadOnly.map(
            (group) => group.id
          );

          const filteredGroups = userGroups.filter(
            (group) =>
              !viewGroupIds.includes(group.id) &&
              !viewGroupReadOnlyIds.includes(group.id)
          );

          filteredGroups.sort((a, b) => a.name.localeCompare(b.name));
          setGroups(filteredGroups);
        } catch (e) {
          console.error("Error in fetchUserGroups:", e.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchGroups();
    }
  }, [tag, open]);

  useEffect(() => {
    setGroupAlreadyAdded(false);
  }, [selectedGroup]);

  const handleAddViewGroup = async () => {
    setIsLoading(true);

    try {
      let result;

      if (conservSostL1) {
        if (permissions === "r") {
          console.log("Adding read-only group for conservazione sostitutiva");
          result = await addViewGroupReadOnly(tag.id, selectedGroup);
        } else if (permissions === "r/w") {
          console.log(
            "Adding read and write group for conservazione sostitutiva"
          );
          result = await addViewGroup(tag.id, selectedGroup);
        }
      } else {
        console.log("Adding read and write group for standard application");
        result = await addViewGroup(tag.id, selectedGroup);
      }

      if (result.code === 0) {
        handleReset();
      } else if (result.code === -1) {
        setGroupAlreadyAdded(true);
      }
    } catch (error) {
      console.error("Error in handleAddViewGroup:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeGroup = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleChangePermissions = (e) => {
    setPermissions(e.target.value);
  };

  const handleReset = () => {
    setSelectedGroup("");
    setPermissions("r");
    setOpen(false);
  };

  const ViewgroupForm = groups && (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel>{t("name")}</InputLabel>
          <Select
            label={t("name")}
            name="Name"
            value={selectedGroup}
            onChange={handleChangeGroup}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {conservSostL1 && (
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel>{t("permissions")}</InputLabel>
            <Select
              label={t("permissions")}
              name="permissions"
              value={permissions}
              onChange={handleChangePermissions}
            >
              <MenuItem value="r">read</MenuItem>
              <MenuItem value="r/w">read/write</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        {groupAlreadyAdded && (
          <Typography variant="body2" color="red">
            {t("group_already_added")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          mt="5%"
          mb="10%"
        >
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("add_group")}
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {groups ? (
              <>
                {ViewgroupForm}
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddViewGroup}
                    disabled={!selectedGroup}
                  >
                    {t("add")}
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography align="center" color="gray" variant="h6">
                  {t("no_group_available")}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("add_group")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>
          {groups ? (
            <>
              {ViewgroupForm}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddViewGroup}
                  disabled={!selectedGroup}
                >
                  {t("add")}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography align="center" color="gray" variant="h6">
                {t("no_group_available")}
              </Typography>
            </Grid>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddViewGroup;
