// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

// Material UI Date Pickers
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// Material UI Icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Components
import MpsTable from "./MpsTable";
import MpsChart from "./MpsChart";
import MpsExportCSV from "./MpsExportCSV";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// Verificator
import verifySignature from "verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const MpsViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [records, setRecords] = useState({});
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [commonMagnitudes, setCommonMagnitudes] = useState([]);
  const [selectedMagnitude, setSelectedMagnitude] = useState("");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [totalSum, setTotalSum] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(
      signaturesCollectionRef,
      where("type", "==", "mps"),
      where("timestamp", ">=", currentTimestampInSecondsString)
    );

    const newAdditionsUnsubscribe = onSnapshot(
      newAdditionsQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            getRecords();
          }
        });
      }
    );

    const getRecords = async () => {
      const records = await fetchRecordsByType(
        tag.id,
        "mps",
        isTagGroupMember,
        startDate,
        endDate
      );

      let groupedRecords = Object.values(
        records.reduce((accumulator, currentRecord) => {
          const sensorId = currentRecord.creator_uuid;
          const sensorName = currentRecord.creator_email || sensorId;
          const magnitude =
            currentRecord.data.magnitude || currentRecord.data.uom;

          if (sensorId != null) {
            if (!accumulator[sensorId]) {
              accumulator[sensorId] = { sensorId, sensorName, magnitudes: [] };
            }

            const existingMagnitudeGroup = accumulator[
              sensorId
            ].magnitudes.find((group) => group.magnitude === magnitude);
            if (!existingMagnitudeGroup) {
              accumulator[sensorId].magnitudes.push({
                magnitude,
                records: [currentRecord],
              });
            } else {
              existingMagnitudeGroup.records.push(currentRecord);
            }
          }

          return accumulator;
        }, {})
      );

      groupedRecords = groupedRecords.filter(
        (sensor) => sensor.sensorId != null
      );
      groupedRecords.forEach((sensor) => {
        sensor.magnitudes.forEach((magnitudeGroup) => {
          magnitudeGroup.records.sort(
            (a, b) => a.data.timestamp_end - b.data.timestamp_end
          );
        });
      });

      let totalSum = 0;
      groupedRecords.forEach((sensorId) => {
        let sensorSum = 0;
        sensorId.magnitudes.forEach((magnitudeGroup) => {
          if (magnitudeGroup.magnitude === "W") {
            const sum = magnitudeGroup.records.reduce(
              (acc, record) => acc + parseFloat(record.data.value),
              0
            );
            sensorSum += sum;
          }
        });
        totalSum += sensorSum;
      });

      setRecords(groupedRecords);
      setTotalSum(totalSum);
      setIsLoading(false);
    };

    getRecords();

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [tag, isTagGroupMember, startDate, endDate]);

  useEffect(() => {
    if (records.length > 0) {
      const allSensors = records.map((record) => record.sensorId);
      setSelectedSensors(allSensors);
    }
  }, [records]);

  useEffect(() => {
    const allMagnitudes = new Set();
    selectedSensors.forEach((sensorId) => {
      const sensor = records.find((record) => record.sensorId === sensorId);
      sensor?.magnitudes.forEach((magnitude) => {
        allMagnitudes.add(magnitude.magnitude);
      });
    });
    setCommonMagnitudes([...allMagnitudes]);
  }, [selectedSensors, records]);

  useEffect(() => {
    setSelectedMagnitude(commonMagnitudes[0]);
  }, [commonMagnitudes]);

  useEffect(() => {
    if (!selectedMagnitude || selectedSensors.length === 0) return;

    const getColorForSensor = (sensorId) => {
      if (sensorId === "Micro PV - demo") {
        return "green";
      }

      if (sensorId === "Power Demo Box 7") {
        return "red";
      }

      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    };

    const datasets = selectedSensors
      .map((sensorId, index) => {
        const sensorData = records.find(
          (record) => record.sensorId === sensorId
        );
        const sensorDataset = {
          label: sensorData ? sensorData.sensorName : sensorId,
          data:
            sensorData &&
            sensorData.magnitudes &&
            sensorData.magnitudes
              .find((m) => m.magnitude === selectedMagnitude)
              ?.records.map((record) => ({
                x: record.data.timestamp * 1000,
                y: record.data.value,
              })),
          borderColor: getColorForSensor(sensorId),
          backgroundColor: "rgba(0, 0, 0, 0)",
          type: "line",
        };

        return [sensorDataset];
      })
      .flat();

    setChartData({
      datasets,
    });
  }, [selectedSensors, selectedMagnitude, records]);

  const handleChangeSensors = (event) => {
    setSelectedSensors(event.target.value);
  };

  const handleChangeSelectedMagnitude = (event) => {
    setSelectedMagnitude(event.target.value);
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    setRecord(record);
    handleOpenView();
  };

  return (
    <>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {verificationLoading && <LoadingDialog open={verificationLoading} />}

          <Grid container spacing={5}>
            <Grid item container xs={12} spacing={2}>
              <Grid item container xs={12} sm={8} spacing={2}>
                <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label={t("start_date")}
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label={t("end_date")}
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </LocalizationProvider>
                </Grid>

                {records && selectedSensors && selectedSensors.length !== 0 && (
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel id="Source Name">
                        {t("source_name")}
                      </InputLabel>
                      <Select
                        labelId={t("source_name")}
                        id="Source Name"
                        multiple
                        value={selectedSensors}
                        onChange={handleChangeSensors}
                        input={<OutlinedInput label={t("source_name")} />}
                        renderValue={(selected) =>
                          selected
                            .map((sensorId) => {
                              const sensor = records.find(
                                (record) => record.sensorId === sensorId
                              );
                              return sensor ? sensor.sensorName : sensorId;
                            })
                            .join(", ")
                        }
                      >
                        {records
                          .sort((a, b) =>
                            a.sensorName.localeCompare(b.sensorName)
                          )
                          .map((sensor) => (
                            <MenuItem
                              key={sensor.sensorId}
                              value={sensor.sensorId}
                            >
                              <Checkbox
                                checked={
                                  selectedSensors.indexOf(sensor.sensorId) > -1
                                }
                              />
                              <ListItemText primary={sensor.sensorName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {selectedMagnitude && commonMagnitudes && (
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <InputLabel id="magnitude">{t("magnitude")}</InputLabel>
                      <Select
                        labelId={t("magnitude")}
                        id="magnitude"
                        value={selectedMagnitude}
                        onChange={handleChangeSelectedMagnitude}
                        input={<OutlinedInput label={t("magnitude")} />}
                      >
                        {commonMagnitudes.map((magnitude) => (
                          <MenuItem key={magnitude} value={magnitude}>
                            {magnitude}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>

              <Grid item container xs={12} sm={4} justifyContent="flex-end">
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ height: "100%" }}
                    startIcon={<FileDownloadIcon />}
                    onClick={() => setOpenCsvModal(true)}
                  >
                    {t("export_csv")}
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <MpsChart chartData={chartData} />
              </Grid>
            </Grid>

            {tag.id === "E4qSNCkLyz" && (
              <Grid item xs={12}>
                <Typography variant="h5">
                  <b>{t("overall_power_balance")}: </b>
                  {totalSum / 12} Wh
                </Typography>
              </Grid>
            )}

            {selectedSensors && selectedSensors.length !== 0 && (
              <Grid item xs={12}>
                <MpsTable
                  records={records}
                  selectedSensors={selectedSensors}
                  checkVerification={checkVerification}
                  handleView={handleView}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Grid>
            )}
          </Grid>

          <MpsExportCSV
            tag={tag.id}
            open={openCsvModal}
            handleClose={() => setOpenCsvModal(false)}
            isTagGroupMember={isTagGroupMember}
          />

          {record && (
            <DataDialog
              data={{ ...record, databoxName: tag.name || "N/A" }}
              open={openView}
              onClose={() => setOpenView(false)}
            />
          )}

          {verification && (
            <DatapointIntegrityInspector
              verification={verification}
              open={openVerify}
              setOpen={setOpenVerify}
            />
          )}
        </>
      )}
    </>
  );
};

export default MpsViewer;
