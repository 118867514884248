// React
import React, { useState, useEffect, useCallback, useRef } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

// Material UI Icons
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";

// Components
import CDNFileFilterFormDialog from "./CDNFileFilterFormDialog";
import CDNFileTable from "./CDNFileTable";
import CDNPreservationProofDialog from "./CDNPreservationProofDialog";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// RecordOperations
import fetchCDNFileRecords from "RecordOperations/CDNFileRecords/fetchCDNFileRecords";

// MetadataSchemaFilters
import { metadataSchemaFilters } from "./metadataSchemaFilters";

// initialFilters
import { initialFilters } from "./initialFilters";
import { LoadingComponent } from "ui-components/LoadingComponent";

// A ---------------------------------------------------------------------- M

const CDNFileViewer = ({ tag, isTagGroupMember, isTagGroupMemberReadWrite, openUploadGenDrawer }) => {
  const { t } = useTranslation();

  const [CDNFileRecords, setCDNFileRecords] = useState([]);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isRecent, setIsRecent] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [isPreservationProofDialogOpen, setIsPreservationProofDialogOpen] = useState(false);
  const [record, setRecord] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const generateFilterLabels = (metadataSchemaFilters) => {
    const labels = {};
    Object.keys(metadataSchemaFilters).forEach((key) => {
      labels[key] = metadataSchemaFilters[key].label;
    });
    return labels;
  };

  const filterLabels = generateFilterLabels(metadataSchemaFilters);

  const isRecentRef = useRef(isRecent);

  useEffect(() => {
    isRecentRef.current = isRecent;
  }, [isRecent]);

  const getCDNFileRecords = useCallback(async (isRecentFlag = false, criteria = {}) => {
    try {
      console.log(isTagGroupMember);
      const records = await fetchCDNFileRecords(tag.id, isTagGroupMember, criteria, isRecentFlag);
      console.log(records);
      if (records) {
        records.sort((a, b) => b.data.timestamp - a.data.timestamp);
        setCDNFileRecords(records);
      }
    } catch (error) {
      console.error("Error in fetching record:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(signaturesCollectionRef, where("type", "==", "CDNFile"), where("timestamp", ">=", currentTimestampInSecondsString));

    const newAdditionsUnsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" && isRecentRef.current) {
          getCDNFileRecords(true);
        }
      });
    });

    getCDNFileRecords(true);

    return () => {
      newAdditionsUnsubscribe();
    };
  }, []);

  const handleSearch = () => {
    if (!isRecent) {
      setIsRecent(true);
      setSelectedFilters(initialFilters);
      getCDNFileRecords(true);
    }
  };

  const handleFilter = (criteria) => {
    setIsRecent(false);
    setSelectedFilters(criteria);
    getCDNFileRecords(false, criteria);
  };

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const handleResetFilters = () => {
    setSelectedFilters(initialFilters);
    setIsRecent(true);
    getCDNFileRecords(true);
  };

  const handleOpenPreservationProofDialog = (record) => {
    setRecord(record);
    setIsPreservationProofDialogOpen(true);
  };

  const handleClosePreservationProofDialog = () => {
    setIsPreservationProofDialogOpen(false);
  };

  const filtersAreActive = () => {
    return Object.keys(selectedFilters).some((key) => selectedFilters[key] !== initialFilters[key]);
  };

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <>
      <Grid container spacing={3}>
        {/* CDN Toolbar */}
        <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
          {isTagGroupMember && (
            <Grid item xs={12} sm={12} md={2} lg={1.6}>
              <Button variant="contained" fullWidth onClick={handleSearch} startIcon={<HistoryIcon />}>
                {t("view_recents")}
              </Button>
            </Grid>
          )}
          {isTagGroupMember && (
            <Grid item xs={12} sm={12} md={2} lg={1.6}>
              <Button variant="contained" fullWidth onClick={handleOpenFilterDialog} startIcon={<SearchIcon />}>
                {t("search")}
              </Button>
            </Grid>
          )}
          {isTagGroupMemberReadWrite && (
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Button variant="contained" fullWidth onClick={openUploadGenDrawer} startIcon={<AddIcon />}>
                {t("upload")}
              </Button>
            </Grid>
          )}
        </Grid>

        {/* Selected Filter */}
        {filtersAreActive() && (
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
            <Typography variant="subtitle1" fontWeight="bold" mr="1%">
              {t("selected_filters")}:
            </Typography>
            {Object.keys(selectedFilters).map((key) => selectedFilters[key] && <Chip key={key} label={`${filterLabels[key]}: ${selectedFilters[key]}`} />)}
            <Grid item container xs={12} justifyContent="flex-end">
              <Button variant="contained" onClick={handleResetFilters} startIcon={<RefreshIcon />} size="small">
                {t("reset_filters")}
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Documents Table */}
        {CDNFileRecords && CDNFileRecords.length > 0 ? (
          <Grid item xs={12}>
            <CDNFileTable records={CDNFileRecords} handleOpenPreservationProofDialog={handleOpenPreservationProofDialog} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CDNFileTable records={[]} />
          </Grid>
        )}
      </Grid>

      {/* Filters Dialog */}
      {isFilterDialogOpen && <CDNFileFilterFormDialog open={isFilterDialogOpen} onClose={handleCloseFilterDialog} onFilter={handleFilter} currentFilters={selectedFilters} />}

      {/* Preservation Proof Dialog */}
      {isPreservationProofDialogOpen && record && (
        <CDNPreservationProofDialog record={record} open={isPreservationProofDialogOpen} setOpen={setIsPreservationProofDialogOpen} onClose={handleClosePreservationProofDialog} />
      )}
    </>
  );
};

export default CDNFileViewer;
