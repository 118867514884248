// React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  useMediaQuery,
  useTheme,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  ManageSearch as ManageSearchIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const AggregatedTableHead = ({ order, orderBy, onRequestSort }) => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      {
        id: "id",
        label: t("id"),
      },
      {
        id: "timestamp",
        label: t("generation_datetime"),
      },
      { id: "creator", label: t("created_by") },
      { id: "type", label: t("type") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

AggregatedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const AggregatedTable = ({ records, checkVerification, handleView }) => {
  console.log(records);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("timestamp");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  const sortedRecords = stableSort(records, getComparator(order, orderBy));

  return (
    <Box>
      <TableContainer>
        <Table>
          <AggregatedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? sortedRecords.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : sortedRecords
            ).map((record) => {
              let type;

              if (record.type === "img") {
                type = t("image");
              } else if (record.type === "doc") {
                type = t("document");
              } else if (record.type === "info") {
                type = t("note");
              } else if (record.type === "like_sirti") {
                type = "like_sirti";
              } else if (record.type === "source_telemetrics") {
                type = "source_telemetrics";
              } else {
                type = t("custom");
              }

              return (
                <TableRow
                  key={record.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell align="left">{record.txid}</TableCell>
                  <TableCell align="left">
                    {record.timestamp
                      ? convertTimestamp(record.timestamp)
                      : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {record.creator_email ? record.creator_email : "N/A"}
                  </TableCell>
                  <TableCell align="left">{type}</TableCell>
                  <TableCell align="left">
                    {record.public ? t("public") : t("private")}
                  </TableCell>
                  <TableCell align="right" size="small">
                    <Tooltip title={t("details")} placement="top">
                      <IconButton
                        color="primary"
                        onClick={() => handleView(record)}
                      >
                        <ManageSearchIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("verify")} placement="top">
                      <IconButton
                        sx={{ color: "green" }}
                        onClick={() => checkVerification(record)}
                      >
                        <VerifiedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={records.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions
              count={records.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

AggregatedTable.propTypes = {
  records: PropTypes.array.isRequired,
  checkVerification: PropTypes.func.isRequired,
  handleView: PropTypes.func.isRequired,
};

export default AggregatedTable;
