// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Material UI Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import getDataKeysByOwner from "datakey/getDataKeysByOwner";
import addAuthorizedKey from "tag/addAuthorizedKey";

// A ---------------------------------------------------------------------- M

const AddDataKey = ({ tag, dataKeys, open, setOpen }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [keys, setKeys] = useState([]);
  const [addedKeys, setAddedKeys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => window.removeEventListener("popstate", handlePopstate);
  }, [open]);

  useEffect(() => {
    const fetch = async () => {
      const fetchedKeys = await getDataKeysByOwner(user.uid);
      setKeys(fetchedKeys);
      setLoading(false);
    };

    if (open) fetch();
  }, [dataKeys, user.uid, open]);

  const handleAddKey = async (keyId) => {
    await addAuthorizedKey(tag.id, keyId);
    setAddedKeys((current) => [...current, keyId]);
  };

  const handleReset = () => {
    setAddedKeys([]);
    setOpen(false);
  };

  const isKeyAdded = (keyId) =>
    addedKeys.includes(keyId) || dataKeys.some((key) => key.id === keyId);

  const TableRowWithToggle = ({ name, datakey, added, handleAdd }) => {
    const [showDataKey, setShowDataKey] = useState(false);

    const toggleDataKeyVisibility = () => {
      setShowDataKey(!showDataKey);
    };

    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell
          align="left"
          sx={{
            minWidth: !showDataKey ? "60px" : "120px",
            maxWidth: !showDataKey ? "60px" : "120px",
          }}
        >
          {showDataKey ? datakey : "••••••"}
          <IconButton onClick={toggleDataKeyVisibility} size="small">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right" size="small">
          {!added ? (
            <Tooltip title={t("add")} placement="top">
              <IconButton color="primary" onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton disabled>
              <CheckIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderTable = () => (
    <Grid item container spacing={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("name")}</TableCell>
              <TableCell align="left">{t("value")}</TableCell>
              <TableCell align="right">{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((key) => {
              const added = isKeyAdded(key.id);
              return (
                <TableRowWithToggle
                  key={key.id}
                  name={key.name}
                  datakey={key.datakey}
                  added={added}
                  handleAdd={() => !added && handleAddKey(key.id)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          mt="5%"
          mb="10%"
        >
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("add_datakey")}
            </Typography>
          </Grid>
        </Grid>
        {loading ? <LoadingComponent /> : renderTable()}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("add_datakey")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {loading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>{renderTable()}</DialogContent>
      )}
    </Dialog>
  );
};

export default AddDataKey;
