// React
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// Material UI Icons
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileOpenIcon from "@mui/icons-material/FileOpen";

// Components
import MetadataField from "./MetadataField";

// MetadataSchema
import { metadataSchema, metadataSections } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
}));

const CDNFileForm = ({ isMobile, source, blobURL, fileName, documentProperties, setDocumentProperties, handleFileChange, handleFileClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleClickOpen = (content) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent("");
  };

  const handleChangeDocumentProperties = (fieldKey, value) => {
    setDocumentProperties({ ...documentProperties, [fieldKey]: value });
  };

  return (
    <>
      <Grid container spacing={5}>
        {/* Section: File Upload */}
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("upload_file")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={12} sm={1}>
              <Typography>{t("file")} *</Typography>
            </Grid>
            <Grid item xs={12} sm={11}>
              <input
                className={classes.input}
                id="new-upload-button"
                type="file"
                accept="application/pdf, text/xml, application/xml, text/plain, text/csv, image/png"
                onChange={(e) => handleFileChange(e.target)}
              />
              <label htmlFor="new-upload-button">
                <Button startIcon={<FileUploadIcon />} variant="contained" component="span" sx={{ width: isMobile ? "100%" : "30%" }}>
                  {t("select_file")}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Typography>{t("file_name")} *</Typography>
            </Grid>
            {source ? (
              <Grid item xs={12} sm={11}>
                <Button startIcon={<FileOpenIcon />} onClick={() => handleFileClick(blobURL)} variant="contained" sx={{ width: isMobile ? "100%" : "30%" }}>
                  {fileName || t("selected_file")}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={10.5}>
                <Typography>{t("no_file_selected")}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        {source && (
          <>
            <Grid item container spacing={3}>
              {metadataSections.map((section) => (
                <React.Fragment key={section.title}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="gray" fontWeight="bold">
                      {section.title}
                    </Typography>
                    <Divider />
                  </Grid>

                  {section.fields.map((fieldKey) => (
                    <MetadataField
                      key={fieldKey}
                      fieldKey={fieldKey}
                      metadata={metadataSchema}
                      documentProperties={documentProperties}
                      handleChange={handleChangeDocumentProperties}
                      handleClickOpen={handleClickOpen}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          </>
        )}
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CDNFileForm;
