// React
import React from "react";
import { useNavigate } from "react-router-dom";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

// Material UI Icons
import LoginIcon from "@mui/icons-material/Login";

// Components
import Copyright from "../../Copyright";

// A ---------------------------------------------------------------------- M

const NavigationItem = ({ onClick, icon, text }) => (
  <ListItem
    onClick={onClick}
    sx={{
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
      },
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const DefaultSidebar = ({ drawerWidth, handleDrawerToggle, drawerOpen }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleSignIn = () => {
    handleDrawerToggle();
    navigate("/signin");
  };

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? drawerOpen : true}
      onClose={handleDrawerToggle}
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#eceff1",
          color: "#37474f",
        },
      }}
    >
      <Toolbar />
      <List>
        <NavigationItem
          key="Sign In"
          onClick={handleSignIn}
          icon={<LoginIcon sx={{ color: "black" }} />}
          text={t("sign_in")}
        />
      </List>
      <Box sx={{ p: 2, mt: "auto", backgroundColor: "#cfd8dc" }}>
        <Copyright />
      </Box>
    </Drawer>
  );
};

export default DefaultSidebar;
