// React
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Grid,
  Select,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  ManageSearch as ManageSearchIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const SensorDataAggregatedTableHead = ({ selectedType }) => {
  const { t } = useTranslation();

  const headCellsData = useMemo(
    () => [
      { id: "id", label: t("id") },
      {
        id: "timestamp",
        label: t("generation_datetime"),
      },
      { id: "value", label: t("value") },
      {
        id: "measure_unit",
        label: t("measure_unit"),
      },
      { id: "magnitude", label: t("magnitude") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  const headCellsPredictions = useMemo(
    () => [
      { id: "id", label: t("id") },
      {
        id: "timestamp",
        label: t("generation_datetime"),
      },
      {
        id: "windowStart",
        label: t("window_start"),
      },
      { id: "windowEnd", label: t("window_end") },
      { id: "prediction", label: t("prediction") },
      { id: "alarm", label: t("alarm") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  const headCells =
    selectedType === "predictions" ? headCellsPredictions : headCellsData;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const SensorDataAggregatedTable = ({
  records,
  aiDataOutputRecords,
  selectedSensors,
  checkVerification,
  handleView,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  console.log(records);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState("");
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (selectedSensor) {
        if (selectedType === "data") {
          const filteredRecords = records.reduce((acc, record) => {
            if (record.sensorId === selectedSensor) {
              record.magnitudes.forEach((mag) => acc.push(...mag.records));
            }
            return acc;
          }, []);
          setFilteredRecords(filteredRecords.reverse());
        } else if (selectedType === "predictions") {
          const predictions = aiDataOutputRecords[selectedSensor] || [];
          setFilteredRecords(predictions.reverse());
        } else {
          setFilteredRecords([]);
        }
      } else {
        setFilteredRecords([]);
      }
    };

    fetchData();
  }, [selectedSensor, selectedType, records, aiDataOutputRecords]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSensor = (event) => {
    setSelectedSensor(event.target.value);
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredRecords.length)
      : 0;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <Select
            fullWidth
            value={selectedSensor}
            onChange={handleChangeSensor}
            displayEmpty
            inputProps={{ "aria-label": "Select Sensor" }}
          >
            <MenuItem value="" disabled>
              {t("select_sensor")}
            </MenuItem>
            {selectedSensors.map((sensorId) => (
              <MenuItem key={sensorId} value={sensorId}>
                {sensorId}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select
            fullWidth
            value={selectedType}
            onChange={handleChangeType}
            displayEmpty
            inputProps={{ "aria-label": "Select Type" }}
          >
            <MenuItem value="" disabled>
              {t("select_type")}
            </MenuItem>
            <MenuItem value="data">{t("show_data")}</MenuItem>
            <MenuItem value="predictions">{t("show_predictions")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <SensorDataAggregatedTableHead selectedType={selectedType} />
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRecords.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRecords
            ).map((record) => {
              if (selectedType === "data") {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    {isMobile ? (
                      <TableCell align="left">
                        {record.id.substring(0, 6)}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{record.id}</TableCell>
                    )}
                    <TableCell align="left">
                      {convertTimestamp(record.data.timestamp_end) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.avg || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.measure_unit || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.magnitude || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.public ? t("public") : t("private")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button onClick={() => handleView(record)}>
                        {t("details")}
                      </Button>
                      <Button onClick={() => checkVerification(record)}>
                        {t("verify")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              } else if (selectedType === "predictions") {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    {isMobile ? (
                      <TableCell align="left">
                        {record.id.substring(0, 6)}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{record.id}</TableCell>
                    )}
                    <TableCell align="left">
                      {convertTimestamp(record.data.timestamp) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {convertTimestamp(record.data.windowStart) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {convertTimestamp(record.data.windowEnd) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.prediction || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.alarm || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.public ? t("public") : t("private")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Tooltip title={t("details")} placement="top">
                        <IconButton
                          color="primary"
                          onClick={() => handleView(record)}
                        >
                          <ManageSearchIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("verify")} placement="top">
                        <IconButton
                          sx={{ color: "green" }}
                          onClick={() => checkVerification(record)}
                        >
                          <VerifiedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={8}
                  count={filteredRecords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions
              count={filteredRecords.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default SensorDataAggregatedTable;
