// React
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

// Material UI Icons
import CloseIcon from "@mui/icons-material/Close";

// Components
import { LoadingComponent, LoadingDialog } from "./LoadingComponent";
import { Puller } from "./Puller";

// Functions
import addSeenTag from "user/addSeenTag";

// A ---------------------------------------------------------------------- M

export const LoginSuccessful = ({ open, handleClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={handleClose}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{
        mt: { md: "4%" },
        mb: { xs: "4%", sm: "0%" },
        width: isMobile ? "100%" : "auto",
        left: isMobile ? 0 : "auto",
        right: isMobile ? 0 : "auto",
      }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        <Typography align="center">{t("login_successful")}</Typography>
      </Alert>
    </Snackbar>
  );
};

export const LoginError = ({ open, handleClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={handleClose}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{
        mt: { md: "4%" },
        mb: { xs: "4%", sm: "0%" },
        width: isMobile ? "100%" : "auto",
        left: isMobile ? 0 : "auto",
        right: isMobile ? 0 : "auto",
      }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        <Typography align="center">{t("email_password_wrong")}</Typography>
      </Alert>
    </Snackbar>
  );
};

export const ResetPasswordDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{t("reset_password_text")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {t("sign_in")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DataBoxFound = ({ tag, open, setOpen }) => {
  const { user, conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleCancel = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await addSeenTag(tag.id, user.uid, tag.name, description);
    } catch (error) {
      console.log("Error in handleSave:", error.message);
    } finally {
      setIsSaving(false);
      setOpen(false);
      window.location.reload();
    }
  };

  return isSaving ? (
    <LoadingDialog />
  ) : (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle fontWeight="bold">
        {conservSostL1 ? t("save_archive") : t("save_databox")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography gutterBottom>
          {!conservSostL1 ? t("databox") : t("archive")} <b>{tag.name}</b>{" "}
          {t("found_text_part")}
        </Typography>
        <TextField
          margin="dense"
          id="Description"
          label={t("description")}
          name="Description"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button fullWidth variant="contained" onClick={handleSave}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CertificationSuccessful = ({ open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {!conservSostL1
          ? t("certification_successful")
          : t("upload_successful")}
      </Alert>
    </Snackbar>
  );
};

export const CertificationError = ({ open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {!conservSostL1 ? t("certification_error") : t("upload_error")}
      </Alert>
    </Snackbar>
  );
};

export const DataboxCreationSuccessfulSnackbar = ({ open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{
        mt: { md: "4%" },
        mb: { xs: "4%", sm: "0%" },
        width: isMobile ? "100%" : "auto",
        left: isMobile ? 0 : "auto",
        right: isMobile ? 0 : "auto",
      }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {!conservSostL1
          ? t("databox_created_successfully")
          : t("archive_created_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const GroupCreationSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("group_created_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const GroupCreationErrorSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("group_created_error")}
      </Alert>
    </Snackbar>
  );
};

export const RemovedKeySnackbar = ({ open, setOpen }) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ mt: { xs: "20%", md: "5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("secret_removed_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const ImportKeyDialog = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.openMnemonicForm}
      onClose={props.handleCloseMnemonicForm}
    >
      <DialogTitle>{t("_24_words_secret")}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: "1%" }}>
          {t("import_text")}
        </DialogContentText>
        {props.loading ? <LoadingComponent /> : props.BIP39Input}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={props.loading}
          onClick={props.handleCloseMnemonicForm}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={props.loading || !props.mnemonic}
          onClick={props.handleLoadMnemonic}
        >
          {t("import")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ImportedKeySnackbar = ({ open, setOpen }) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ mt: { xs: "20%", md: "5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("secret_imported_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const ImportKeyDrawer = (props) => {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.openMnemonicForm}
      onClose={props.handleCloseMnemonicForm}
      onOpen={props.handleOpenMnemonicForm}
      sx={{
        width: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": { width: "100%" },
      }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      <Typography variant="h5" align="center" sx={{ mt: "10%" }}>
        {t("import_secret")}
      </Typography>
      <Box sx={{ p: "6%" }}>
        <Typography variant="body1">{t("import_text")}</Typography>
        {props.loading ? <LoadingComponent /> : props.BIP39Input}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
          <Button
            variant="contained"
            disabled={props.loading}
            onClick={props.handleCloseMnemonicForm}
            sx={{ mr: 1 }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            disabled={props.loading || !props.mnemonic}
            onClick={props.handleLoadMnemonic}
          >
            {t("confirm")}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export const NewCertConfirm = (props) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.confirm}
      onClose={() => props.setConfirm(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle fontWeight="bold">
        {!conservSostL1 ? t("new_certification") : t("upload")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {!conservSostL1 ? t("new_certification_text") : t("new_upload_text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => props.setConfirm(false)}
          size="small"
        >
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={props.handleGenerate} size="small">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewRequestReject = (props) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <Dialog open={props.reject} onClose={() => props.setReject(false)}>
      <DialogTitle>
        {!conservSostL1
          ? t("reject_certification_request")
          : t("reject_upload_request")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {!conservSostL1
            ? t("reject_certification_request_text")
            : t("reject_upload_request_text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.setReject(false)}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={props.handleReject}
          sx={{ bgcolor: "red", color: "white" }}
        >
          {t("reject")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewRequestConfirm = (props) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <Dialog open={props.approval} onClose={() => props.setApproval(false)}>
      <DialogTitle>
        {!conservSostL1
          ? t("new_certification_request")
          : t("new_Upload_request")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {!conservSostL1
            ? t("new_certification_request_text")
            : t("new_upload_request_text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.setApproval(false)}>
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={props.handleSendApprovalRequest}>
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MemberAddedSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("member_added_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const MemberAddedErrorSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("member_added_error")}
      </Alert>
    </Snackbar>
  );
};

export const MemberRemoveConfirm = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.showRemove}
      onClose={() => props.setShowRemove(false)}
      fullWidth
    >
      <DialogTitle>{t("remove_member")}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{t("remove_member_text")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setShowRemove(false)} variant="contained">
          {t("cancel")}
        </Button>
        <Button
          onClick={props.handleRemoveMember}
          variant="contained"
          sx={{ bgcolor: "red", color: "white" }}
        >
          {t("remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MemberRemovedSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("member_removed_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const MemberRemovedErrorSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("member_removed_error")}
      </Alert>
    </Snackbar>
  );
};

export const GroupRemoveConfirm = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.showRemove} onClose={() => props.setShowRemove(false)}>
      <DialogTitle>{t("remove_group")}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{t("remove_group_text")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setShowRemove(false)} variant="contained">
          {t("cancel")}
        </Button>
        <Button
          onClick={props.handleRemoveGroup}
          variant="contained"
          sx={{ bgcolor: "red", color: "white" }}
        >
          {t("remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EnabledPublicKeySuccessful = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("public_key_activated_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const EnabledPublicKeyError = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("public_key_activated_error")}
      </Alert>
    </Snackbar>
  );
};

export const EnabledAPIKeySuccessful = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("api_key_activated_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const EnabledAPIKeyError = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("api_key_activated_error")}
      </Alert>
    </Snackbar>
  );
};

export const NewDataKeySuccessful = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("data_key_created_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const NewDataKeyError = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("data_key_created_error")}
      </Alert>
    </Snackbar>
  );
};

export const InvalidFile = ({ open, setOpen, message }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {message || t("invalid_file")}{" "}
      </Alert>
    </Snackbar>
  );
};

export const ForwardRouteRemoveConfirm = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.showRemove}
      onClose={() => props.setShowRemove(false)}
      fullWidth
    >
      <DialogTitle>{t("remove_forward_route")}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{t("remove_forward_route_text")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setShowRemove(false)} variant="contained">
          {t("cancel")}
        </Button>
        <Button
          onClick={props.handleRemoveForwardRoute}
          variant="contained"
          sx={{ bgcolor: "red", color: "white" }}
        >
          {t("remove")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ForwardRouteRemovedSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {t("forward_route_removed_successfully")}
      </Alert>
    </Snackbar>
  );
};

export const ForwardRouteRemovedErrorSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={2500}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {t("forward_route_removed_error")}
      </Alert>
    </Snackbar>
  );
};

// Install PWA
export const InstallBanner = (props) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={props.showInstallBanner}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t("install_pwa_description")}
      action={[
        <Button key="action1" onClick={props.handleCloseInstallBanner}>
          {t("dismiss")}
        </Button>,
        <Button key="action2" onClick={props.handleClick}>
          {t("install")}
        </Button>,
      ]}
      sx={{ mt: { xs: "55px", md: "5%" } }}
    />
  );
};

// Open with PWA
export const OpenWithBanner = (props) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={props.showOpenBanner}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t("open_with_app")}
      action={[
        <Button key="action1" onClick={props.handleCloseOpenBanner}>
          {t("dismiss")}
        </Button>,
        <Link key="action2" target="_blank" to="/signin">
          <Button>{t("open")}</Button>
        </Link>,
      ]}
      sx={{ mt: { xs: "55px", md: "5%" } }}
    />
  );
};

// Update PWA
export const UpdateBanner = (props) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={props.showUpdateBanner}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      message={t("new_pwa_version_available")}
      action={[
        <Button key="action1" onClick={props.reloadPage}>
          {t("refresh")}
        </Button>,
      ]}
      sx={{ mt: { xs: "55px", md: "5%" } }}
    />
  );
};
