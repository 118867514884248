// React
import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// Firebase
import { requestPermission } from "config/firebase";

// UserOperations
import addFCMToken from "UserOperations/addFCMToken";
import isUserAuthorizedForDataBox from "user/isUserAuthorizedForDataBox";
import isUserAuthorizedForDataBoxReadOnly from "user/isUserAuthorizedForDataBoxReadOnly";

// A ---------------------------------------------------------------------- M

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();
  const { tag } = useParams();
  const [allowed, setAllowed] = useState(null);

  useEffect(() => {
    const handleFCMToken = async () => {
      const token = await requestPermission();
      if (token) {
        await addFCMToken(user.uid, token);
      }
    };

    const checkAccess = async () => {
      if (user) {
        await handleFCMToken();

        if (tag) {
          const isTagGroupMember = await isUserAuthorizedForDataBox(
            tag,
            user.uid
          );
          const isTagGroupMemberReadOnly =
            await isUserAuthorizedForDataBoxReadOnly(tag, user.uid);

          if (isTagGroupMember || isTagGroupMemberReadOnly) {
            setAllowed(true);
          } else {
            setAllowed(false);
          }
        } else {
          setAllowed(true);
        }
      } else if (tag) {
        setAllowed(false);
      } else {
        setAllowed(false);
      }
    };

    checkAccess();
  }, [user, tag]);

  if (allowed === null) {
    return null;
  } else if (allowed) {
    return children;
  } else if (tag) {
    return <Navigate to={`/${tag}/public`} state={{ tag: tag }} />;
  } else {
    return <Navigate to="/signin" state={{ tag: tag }} />;
  }
};

export default ProtectedRoute;
