// React
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Box,
  Stack,
} from "@mui/material";

// Material UI Icons
import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsNone as NotificationsNoneIcon,
  QrCode as QrCodeIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Settings as SettingsIcon,
  Stream as StreamIcon,
} from "@mui/icons-material";

// Components
import TagQRCode from "./TagQRCode";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const TagToolbar = ({ tag, isFollowing, handleFollowToggle }) => {
  const { user, conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openQR, setOpenQR] = useState(false);

  const handleQRCode = () => {
    setOpenQR(true);
    window.history.pushState(null, "");
  };

  return (
    <>
      <Box p={{ xs: 1 }}>
        <Paper elevation={4} sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  component={Link}
                  to={`/${tag.id}`}
                  sx={{
                    textDecoration: "none",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {tag.name || "N/A"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: conservSostL1 ? "#00796b" : "#0288d1",
                    color: "#fff",
                    borderRadius: 2,
                    px: 3,
                    py: 1,
                    mt: { xs: 2, md: 0 },
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {conservSostL1 ? t("archive") : t("databox")}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ borderColor: "#424242" }} />
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1.5}>
                <Typography>
                  <b>{t("id")}:</b> {tag.id}
                </Typography>
                <Typography noWrap>
                  <b>{t("name")}:</b> {tag.name || "N/A"}
                </Typography>
                <Typography>
                  <b>{t("description")}:</b> {tag.notes || "N/A"}
                </Typography>
                <Typography>
                  <b>{t("registration_datetime")}:</b>{" "}
                  {tag.firstseen ? convertTimestamp(tag.firstseen) : "N/A"}
                </Typography>
                <Typography>
                  <b>{t("owner")}:</b> {tag.tagOwnerEmail || "N/A"}
                </Typography>
                {conservSostL1 && (
                  <Typography>
                    <b>{t("document_type")}:</b>{" "}
                    {tag.tipologiaDocumentale || "N/A"}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Paper>

        <Box mt={3}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={2}>
              <Button
                startIcon={<PlaylistAddCheckIcon />}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => navigate(`/${tag.id}`)}
              >
                {conservSostL1 ? t("documents") : t("certifications")}
              </Button>
            </Grid>
            {tag.tagOwner && tag.tagOwner === user.uid && (
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  startIcon={<SettingsIcon />}
                  variant="contained"
                  fullWidth
                  onClick={() => navigate(`/${tag.id}/settings`)}
                >
                  {t("settings")}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={2}>
              <Button
                startIcon={<StreamIcon />}
                variant="contained"
                fullWidth
                onClick={() => navigate(`/${tag.id}/monitor`)}
              >
                {t("monitor")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                startIcon={<QrCodeIcon />}
                variant="contained"
                fullWidth
                onClick={handleQRCode}
              >
                {t("qr_code")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                startIcon={
                  isFollowing ? (
                    <NotificationsNoneIcon />
                  ) : (
                    <NotificationsActiveIcon />
                  )
                }
                variant="contained"
                fullWidth
                onClick={handleFollowToggle}
              >
                {isFollowing ? t("unfollow") : t("follow")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <TagQRCode tag={tag.id} open={openQR} setOpen={setOpenQR} />
    </>
  );
};

export default TagToolbar;
