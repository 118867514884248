// SafeTwin
import {
  calculateSHA256,
  toHex,
  ed25519Sign,
  genRndString,
} from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const sendCertificationStreamDoc = async (
  keypair,
  fileByteArray,
  comment,
  timestamp,
  tdr
) => {
  try {
    // Private and public keys
    const pvt = keypair.sk;
    const pbk = keypair.pk;

    // Calculate file SHA256
    const fileSHA256hex = toHex(calculateSHA256(fileByteArray));

    // Calculate hsh field
    const hsh = toHex(calculateSHA256(fileSHA256hex + comment + timestamp));

    // Concatenate hsh and tdr (if defined)
    const payload = tdr ? hsh + tdr : hsh;

    // Calculate payload SHA256
    const payloadSHA256 = toHex(calculateSHA256(payload));

    // Sign the payload SHA256
    const sig = toHex(ed25519Sign(payloadSHA256, pvt));

    // Calculate random string of 12 unicode characters (12 bytes)
    const rnd = genRndString(12);

    // Calculate Ed25519 signature of the rnd
    const rnds = toHex(ed25519Sign(rnd, pvt));

    // Call bcStore API for certification stream
    const response = await fetch(
      "https://serving.armilis.com/api/v0.2/bcStore",
      {
        method: "POST",
        body: JSON.stringify({
          payload: {
            hsh: hsh,
            ...(tdr && { tdr: tdr }),
            sig: sig,
          },
          authentication: {
            pbk: toHex(pbk),
            rnd: rnd,
            rnds: rnds,
          },
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error in sending certification stream doc:", error.message);
    return {
      error: error.message,
    };
  }
};

export default sendCertificationStreamDoc;
