// React
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// react-pwa-install
import ReactPWAInstallProvider from "react-pwa-install";

// Contexts
import { AuthContextProvider } from "context/AuthContext";

// i18n
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// App
import App from "App";

// Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// reportWebVitals
import reportWebVitals from "reportWebVitals";

// A ---------------------------------------------------------------------- M

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactPWAInstallProvider>
    <Router>
      <I18nextProvider i18n={i18n}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </I18nextProvider>
    </Router>
  </ReactPWAInstallProvider>
);

// Register service worker (only in production)
if (process.env.NODE_ENV === "production") {
  serviceWorkerRegistration.register();
}

reportWebVitals();
