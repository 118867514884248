// React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";
import removeAuthorizedKey from "tag/removeAuthorizedKey";

// A ---------------------------------------------------------------------- M

const EnabledDataKeysTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "id", label: t("id") },
      { id: "name", label: t("name") },
      {
        id: "timestamp",
        label: t("addition_datetime"),
      },
      { id: "value", label: t("value") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnabledDataKeysTableToolbar = ({ openAddDataKeyDialog }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={5}>
        <Typography variant="h6" fontWeight="bold">
          {t("authorized_datakeys")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={openAddDataKeyDialog}
              startIcon={<AddIcon />}
            >
              {t("add_datakey")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DataRow = ({ datakey, tag }) => {
  const [showDataKey, setShowDataKey] = useState(false);
  const { t } = useTranslation();

  const toggleDataKeyVisibility = () => {
    setShowDataKey(!showDataKey);
  };

  return (
    <TableRow sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
      <TableCell align="left">{datakey.id}</TableCell>
      <TableCell align="left">{datakey.name || "N/A"}</TableCell>
      <TableCell align="left">
        {datakey.added_on ? convertTimestamp(datakey.added_on) : "N/A"}
      </TableCell>
      <TableCell align="left">
        {showDataKey ? datakey.datakey : "••••••"}
        <Tooltip title={showDataKey ? t("hide") : t("show")}>
          <IconButton onClick={toggleDataKeyVisibility} size="small">
            {showDataKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="right" size="small">
        <Tooltip title={t("remove")} placement="top">
          <IconButton
            sx={{ color: "red" }}
            onClick={() => removeAuthorizedKey(tag.id, datakey.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const EnabledDataKeysTable = ({ tag, dataKeys, openAddDataKeyDialog }) => {
  console.log(dataKeys);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKeys.length) : 0;

  return (
    <Box>
      <EnabledDataKeysTableToolbar
        openAddDataKeyDialog={openAddDataKeyDialog}
      />
      <br />
      <Divider />
      <TableContainer>
        <Table>
          <EnabledDataKeysTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? dataKeys.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : dataKeys
            ).map((datakey) => {
              return <DataRow key={datakey.id} datakey={datakey} tag={tag} />;
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={dataKeys.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions
              count={dataKeys.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

EnabledDataKeysTable.propTypes = {
  tag: PropTypes.object.isRequired,
  dataKeys: PropTypes.array.isRequired,
  openAddDataKeyDialog: PropTypes.func.isRequired,
};

export default EnabledDataKeysTable;
