// React
import React, { useEffect, useState } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// UserOperations
import activateAPIKey from "UserOperations/activateAPIKey";

// A ---------------------------------------------------------------------- M

const ActivateAPIKey = ({
  APIKey,
  open,
  setOpen,
  handleClose,
  setOpenAPIKeySnackbarSuccessful,
  setOpenAPIKeySnackbarError,
}) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const handleActivate = async () => {
    setLoading(true);

    const data = { APIKey, name, company, type };
    const activation = await activateAPIKey(user.uid, data);

    if (activation.success) {
      setOpenAPIKeySnackbarSuccessful(true);
    } else {
      setOpenAPIKeySnackbarError(true);
    }

    setLoading(false);
    handleReset();
  };

  const handleReset = () => {
    handleClose();
    setName("");
    setCompany("");
    setType("");
  };

  const APIKeyForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="value"
          label={t("value")}
          name="value"
          required
          fullWidth
          variant="outlined"
          value={APIKey}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="Name"
          label={t("name")}
          name="Name"
          required
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="Company"
          label={t("company")}
          name="Name"
          fullWidth
          variant="outlined"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="type"
          label={t("type")}
          name="Type"
          fullWidth
          variant="outlined"
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "65%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          mt="5%"
          mb="10%"
        >
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("activate_api_key")}
            </Typography>
          </Grid>
        </Grid>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {APIKeyForm}
            <Grid container mt="3%">
              <Button
                fullWidth
                variant="contained"
                onClick={handleActivate}
                startIcon={<AddIcon />}
                disabled={!APIKey || !name}
              >
                {t("activate")}
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("activate_api_key")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {loading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>
          {APIKeyForm}
          <Grid item container xs={12} mt="3%">
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleActivate}
                startIcon={<AddIcon />}
                disabled={!APIKey || !name}
              >
                {t("activate")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ActivateAPIKey;
