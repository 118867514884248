// React
import React from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { Grid, Typography, Paper, Divider, Box, Stack } from "@mui/material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const GroupToolbar = ({ group }) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <Box p={{ xs: 1, md: 1 }}>
      <Paper elevation={4} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  textDecoration: "none",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {group.name || "N/A"}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: conservSostL1 ? "#00796b" : "#0288d1",
                  color: "#fff",
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  mt: { xs: 2, md: 0 },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {t("group")}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderColor: "#424242" }} />
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={1.5}>
              <Typography>
                <b>{t("id")}:</b> {group.id}
              </Typography>
              <Typography noWrap>
                <b>{t("name")}:</b> {group.name || "N/A"}
              </Typography>
              <Typography gutterBottom>
                <b>{t("creation_datetime")}</b>:{" "}
                {group.created_on ? convertTimestamp(group.created_on) : "N/A"}
              </Typography>
              <Typography gutterBottom>
                <b>{t("creator")}</b>: {group.creator_email || "N/A"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default GroupToolbar;
