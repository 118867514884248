import { db } from "config/firebase";
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const addFCMToken = async (uid, fcm_token) => {
  try {
    const userRef = doc(db, "fcm_tokens", uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      await setDoc(userRef, {
        fcm_tokens: [fcm_token],
      });
    } else {
      if (
        userDoc.data() &&
        userDoc.data().fcm_tokens &&
        !userDoc.data().fcm_tokens.includes(fcm_token)
      ) {
        await updateDoc(userRef, {
          fcm_tokens: arrayUnion(fcm_token),
        });
      } else {
        await updateDoc(userRef, {
          fcm_tokens: arrayUnion(fcm_token),
        });
      }
    }
  } catch (error) {
    console.error("Add FCM token failed:", error.message);
  }
};

export default addFCMToken;
