// React
import React, { useState } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

// Material UI Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// Components
import { LoadingComponent } from "../LoadingComponent";

// Functions
import addSeenTag from "user/addSeenTag";

// A ---------------------------------------------------------------------- M

const TagConfigure = ({ tag, handleCloseTagConf, setTagConf }) => {
  const { user, conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      label: conservSostL1
        ? t("archive_configure_label_1")
        : t("databox_configure_label_1"),
      description: (
        <>
          <b>{tag}</b> {t("configure_has_been_found_description")}
        </>
      ),
    },
    {
      label: t("configure_label_2"),
      description: t("configure_description_2"),
    },
    {
      label: t("configure_label_3"),
      description: t("configure_description_3"),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddSeenTag = async () => {
    setLoading(true);
    await addSeenTag(tag, user.uid, name, notes);
    setLoading(false);
    setTagConf(false);
    window.location.reload();
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" mt="5%">
        <Grid item>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <CheckCircleOutlineIcon fontSize="large" sx={{ color: "green" }} />
          </Box>
          <Typography variant="h6" color="green">
            {conservSostL1 ? t("archive_found") : t("databox_found")}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ p: "6%" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={steps[0].label}>
            <StepLabel>
              <b>{steps[0].label}</b>
            </StepLabel>
            <StepContent>
              <Typography>{steps[0].description}</Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleCloseTagConf}
                  sx={{ mr: 1 }}
                >
                  {t("no")}
                </Button>
                <Button fullWidth variant="contained" onClick={handleNext}>
                  {t("yes")}
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step key={steps[1].label}>
            <StepLabel>
              <b>{steps[1].label}</b>
            </StepLabel>
            <StepContent>
              <Typography>{steps[1].description}</Typography>
              <TextField
                margin="dense"
                id="Name"
                label={t("name")}
                name="Name"
                required
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("back")}
                </Button>
                <Button fullWidth variant="contained" onClick={handleNext}>
                  {t("confirm")}
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step key={steps[2].label}>
            <StepLabel>
              <b>{steps[2].label}</b>
            </StepLabel>
            <StepContent>
              <Typography>{steps[2].description}</Typography>
              <TextField
                margin="dense"
                id="Description"
                label={t("description")}
                name="Description"
                fullWidth
                variant="outlined"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows={2}
                multiline
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("back")}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleAddSeenTag}
                >
                  {t("confirm")}
                </Button>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
        {loading && <LoadingComponent />}
      </Box>
    </>
  );
};

export default TagConfigure;
