export const metadataSchema = {
  nomeDelDocumento: {
    label: "Nome Documento",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Nome del documento/file così come riconosciuto all' esterno.",
    order: 0,
  },
  tipologiaDocumentale: {
    label: "Tipologia Documentale",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText:
      "Metadato funzionale che indica la tipologia del documento trattato (ad esempio, fatture, contratti, ordini di acquisto, etc). Ogni archivio ha una tipologia documentale assegnata prefissata.",
    order: 1,
  },
  impronta: {
    label: "Impronta",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Rappresenta l'hash del documento.",
    order: 2,
  },
  algoritmo: {
    label: "Algoritmo",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Algoritmo applicato, default = SHA-256",
    order: 3,
  },
  identificativoDocumento: {
    label: "Identificativo Documento",
    type: "text",
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: "Identificativo univoco e persistente associato in modo univoco e permanente al documento informatico in modo da consentirne l' identificazione.",
    order: 4,
  },
  formatoDocumento: {
    label: "Formato Documento",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Formato del file.",
    order: 5,
  },
  prodottoSoftwareNome: {
    label: "Prodotto Software Nome",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Nome del software utilizzato per la creazione del documento, quando rilevabile.",
    order: 6,
  },
  prodottoSoftwareVersione: {
    label: "Prodotto Software Versione",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Versione del software utilizzato per la creazione del documento, quando rilevabile.",
    order: 7,
  },
  prodottoSoftwareProduttore: {
    label: "Prodotto Software Produttore",
    type: "text",
    required: true,
    readOnly: true,
    visible: () => true,
    helperText: "Produttore del software utilizzato per la creazione del documento, quando rilevabile.",
    order: 8,
  },
  versioneDelDocumento: {
    label: "Versione Documento",
    type: "text",
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: "Versione del documento.",
    order: 9,
  },
  modalitaDiFormazione: {
    label: "Modalità Di Formazione",
    type: "select",
    options: ["a", "b", "c", "d"],
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        Indica la modalità di generazione del documento informatico. <br />
        Sono previste le seguenti modalità secondo quanto riportato nelle Linee guida:
        <ul>
          <li>a) Creazione tramite l' utilizzo di strumenti software che assicurino la produzione di documenti nei formati previsti nell' Allegato 2 delle Linee Guida;</li>
          <li>
            b) Acquisizione di un documento informatico per via telematica o su supporto informatico, acquisizione della copia per immagine su supporto informatico di un documento analogico,
            acquisizione della copia informatica di un documento analogico;
          </li>
          <li>
            c) Memorizzazione su supporto informatico in formato digitale delle informazioni risultanti da transazioni o processi informatici o dalla presentazione telematica di dati attraverso moduli
            o formulari resi disponibili all' utente;
          </li>
          <li>
            d) Generazione o raggruppamento anche in via automatica di un insieme di dati o registrazioni, provenienti da una o più banche dati, anche appartenenti a più soggetti interoperanti,
            secondo una struttura logica predeterminata e memorizzata in forma statica.
          </li>
        </ul>
      </>
    ),
    order: 10,
  },
  tipologiaDiFlusso: {
    label: "Tipologia Di Flusso",
    type: "select",
    options: ["U", "E", "I"],
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        Indica se si tratta di un documento in uscita, in entrata o interno.
        <ul>
          <li>U) In uscita</li>
          <li>E) In entrata</li>
          <li>I) Interno</li>
        </ul>
      </>
    ),
    order: 11,
  },
  tipoRegistro: {
    label: "Tipo Registro",
    type: "select",
    options: ["Nessuno", "Protocollo Ordinario/Protocollo Emergenza", "Repertorio/Registro"],
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        Indica il sistema di registrazione adottato: protocollo ordinario/protocollo emergenza, o Repertorio/Registro.
        <ul>
          <li>Nessuno</li>
          <li>Protocollo Ordinario/Protocollo Emergenza</li>
          <li>Repertorio/Registro</li>
        </ul>
      </>
    ),
    order: 12,
  },
  dataRegistrazione: {
    label: "Data Registrazione",
    type: "datetime",
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        E' la data associata al documento all' atto della registrazione.
        <ul>
          <li>Nel caso di documento non protocollato: data di registrazione del documento/ora di registrazione del documento.</li>
          <li>Nel caso di documento protocollato: data di registrazione di protocollo/ora di protocollazione del documento.</li>
        </ul>
      </>
    ),
    order: 13,
  },
  numeroDocumento: {
    label: "Numero Documento",
    type: "text",
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        Numero identificativo del documento nel caso di documento non protocollato (ad esempio, numero fattura), numero di protocollo nel caso di documento protocollato.
        <ul>
          <li>Nel caso di documento non protocollato: numero di registrazione del documento.</li>
          <li>Nel caso di documento protocollato: numero di protocollo.</li>
        </ul>
      </>
    ),
    order: 14,
  },
  codiceRegistro: {
    label: "Codice Registro",
    type: "text",
    required: true,
    readOnly: false,
    visible: (documentProperties) => {
      const tipoRegistroValue = documentProperties.tipoRegistro;
      return tipoRegistroValue === "Protocollo Ordinario/Protocollo Emergenza" || tipoRegistroValue === "Repertorio/Registro";
    },
    helperText: "Identificativo del registro in cui il documento viene registrato, nel caso in cui il tipo registro sia protocollo ordinario/protocollo emergenza, o Repertorio/Registro.",
    order: 15,
  },
  ruolo: {
    label: "Ruolo",
    type: "select",
    options: ["Assegnatario", "Autore", "Destinatario", "Mittente", "Operatore", "RGD", "RSP", "Soggetto che effettua la registrazione", "Altro"],
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        Consente di indicare, a seconda delle necessità, l' autore del documento, il mittente, il destinatario, l' assegnatario. Al fine di rendere i dati di registrazione univoci deve essere sempre
        indicato il Soggetto che effettua la registrazione del documento (tipicamente l' Organizzazione che protocolla). Obbligatorio inoltre indicare almeno l' autore o il mittente. Nel caso di
        documento protocollato deve essere obbligatoriamente indicato il mittente. Per “Operatore” si intende il soggetto autorizzato ad apportare modifiche/integrazioni al documento, la cui
        definizione si renderà obbligatoria nel caso in cui venga compilato il metadato “Tracciature modifiche documento”.
        <ul>
          <li>Assegnatario</li>
          <li>Autore</li>
          <li>Destinatario</li>
          <li>Mittente</li>
          <li>Operatore</li>
          <li>RGD (Responsabile della Gestione Documentale)</li>
          <li>RSP (Responsabile del Servizio di Protocollo)</li>
          <li>Soggetto che effettua la registrazione</li>
          <li>Altro</li>
        </ul>
      </>
    ),
    order: 16,
  },
  tipoSoggetto: {
    label: "Tipo Soggetto",
    type: "select",
    options: (documentProperties) => {
      const ruoloValue = documentProperties.ruolo;
      return ruoloToTipoSoggettoOptions[ruoloValue] || [];
    },
    required: true,
    readOnly: false,
    visible: (documentProperties) => !!documentProperties.ruolo,
    helperText: (
      <>
        Consente di tipizzare i soggetti come persone fisiche, giuridiche, amministrazioni pubbliche (italiane ed estere). Il Tipo Soggetto = SW è indicabile solo se si è indicato il ruolo =
        ”Produttore”. Per ogni Tipo Soggetto sono indicati i metadati di riferimento. Il metadato ha una struttura ricorsiva.
        <ul>
          <li>Se Ruolo = Assegnatario: AS</li>
          <li>
            Se Ruolo = Soggetto che effettua la registrazione:
            <ul>
              <li>PF per Persona Fisica</li>
              <li>PG per Organizzazione</li>
            </ul>
          </li>
          <li>
            Se Ruolo = Mittente o Destinatario o Altro:
            <ul>
              <li>PF per Persona Fisica</li>
              <li>PG per Organizzazione</li>
              <li>PAI per le Amministrazioni Pubbliche italiane (valido solo come mittente nei flussi in entrata, come destinatario nei flussi in uscita)</li>
              <li>PAE per le Amministrazioni Pubbliche estere (valido solo come mittente nei flussi in entrata, come destinatario nei flussi in uscita)</li>
            </ul>
          </li>
          <li>
            Se Ruolo = Autore:
            <ul>
              <li>PF per Persona Fisica</li>
              <li>PG per Organizzazione</li>
              <li>PAI per le Amministrazioni Pubbliche italiane (valido solo nei flussi in entrata)</li>
              <li>PAE per le Amministrazioni Pubbliche estere (valido solo come mittente nei flussi in entrata, come destinatario nei flussi in uscita)</li>
            </ul>
          </li>
          <li>
            Se Ruolo = Operatore o Responsabile della Gestione Documentale o Responsabile del Servizio Protocollo:
            <ul>
              <li>PF per Persona Fisica Se Ruolo = Produttore</li>
              <li>SW per i documenti prodotti automaticamente</li>
            </ul>
          </li>
        </ul>
      </>
    ),
    order: 17,
  },
  cognome: {
    label: "Cognome",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PF";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PF" || tipoSoggetto === "AS";
    },
    helperText: "Cognome soggetto.",
    order: 18,
  },
  nome: {
    label: "Nome",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PF";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PF" || tipoSoggetto === "AS";
    },
    helperText: "Nome soggetto.",
    order: 19,
  },
  codiceFiscale: {
    label: "Codice Fiscale",
    type: "text",
    required: false,
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PF" || tipoSoggetto === "AS" || tipoSoggetto === "PG";
    },
    helperText: "Codice fiscale.",
    order: 20,
  },
  partitaIva: {
    label: "Partita Iva",
    type: "text",
    required: false,
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PG";
    },
    helperText: "Partita IVA.",
    order: 21,
  },
  indirizziDigitaliDiRiferimento: {
    label: "Indirizzi Digitali Di Riferimento",
    type: "text",
    required: false,
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PF" || tipoSoggetto === "PG" || tipoSoggetto === "PAI" || tipoSoggetto === "PAE" || tipoSoggetto === "AS";
    },
    helperText: "Indirizzi digitali di riferimento.",
    order: 22,
  },
  denominazioneOrganizzazione: {
    label: "Denominazione Organizzazione",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PG" || tipoSoggetto === "AS";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PG" || tipoSoggetto === "AS";
    },
    helperText: "Denominazione organizzazione.",
    order: 23,
  },
  denominazioneUfficio: {
    label: "Denominazione Ufficio",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "AS";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PG" || tipoSoggetto === "PAE" || tipoSoggetto === "AS";
    },
    helperText: "Denominazione ufficio.",
    order: 24,
  },
  denominazioneAmministrazioneCodiceIPA: {
    label: "Denominazione Amministrazione/Codice IPA",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PAI";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PAI";
    },
    helperText: "Denominazione Amministrazione/Codice IPA.",
    order: 25,
  },
  denominazioneAmministrazioneAOOCodiceIPAAOO: {
    label: "Denominazione Amministrazione AOO/Codice IPA AOO",
    type: "text",
    required: false,
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PAI";
    },
    helperText: "Denominazione Amministrazione AOO/Codice IPA AOO.",
    order: 26,
  },
  denominazioneAmministrazioneUORCodiceIPAUOR: {
    label: "Denominazione Amministrazione UOR/Codice IPA UOR",
    type: "text",
    required: false,
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PAI";
    },
    helperText: "Denominazione Amministrazione UOR/Codice IPA UOR.",
    order: 27,
  },
  denominazioneAmministrazione: {
    label: "Denominazione Amministrazione",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PAE";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "PAE";
    },
    helperText: "Denominazione amministrazione.",
    order: 28,
  },
  denominazioneSistema: {
    label: "Denominazione Sistema",
    type: "text",
    required: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "SW";
    },
    readOnly: false,
    visible: (documentProperties) => {
      const tipoSoggetto = documentProperties.tipoSoggetto;
      return tipoSoggetto === "SW";
    },
    helperText: "Denominazione sistema.",
    order: 29,
  },
  numeroAllegati: {
    label: "Numero Allegati",
    type: "text",
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: "Indica il numero di allegati al documento e, nell' eventualità che il numero di allegati indicati sia maggiore di zero. Inserire un numero intero compreso tra 0 e 9999.",
    order: 30,
  },
  idDocAllegati: {
    label: "idDocAllegati",
    type: "text",
    required: (documentProperties) => {
      const numeroAllegati = documentProperties.numeroAllegati;
      return numeroAllegati > 0;
    },
    readOnly: false,
    visible: (documentProperties) => {
      const numeroAllegati = documentProperties.numeroAllegati;
      return numeroAllegati > 0;
    },
    helperText: "Identificativo del documento relativo all' allegato. Da indicare per ogni allegato se Numero allegati > 0.",
    order: 31,
  },
  descrizioneAllegati: {
    label: "Descrizione Allegati",
    type: "text",
    required: (documentProperties) => {
      const numeroAllegati = documentProperties.numeroAllegati;
      return numeroAllegati > 0;
    },
    readOnly: false,
    visible: (documentProperties) => {
      const numeroAllegati = documentProperties.numeroAllegati;
      return numeroAllegati > 0;
    },
    helperText: "Testo libero. Da indicare per ogni allegato se Numero allegati > 0.",
    order: 32,
  },
  indiceDiClassificazione: {
    label: "Indice Di Classificazione",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText: "Codifica del documento secondo il Piano di classificazione utilizzato.",
    order: 33,
  },
  descrizioneClassificazione: {
    label: "Descrizione Classificazione",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText: "Descrizione per esteso dell' Indice di classificazione indicato.",
    order: 34,
  },
  pianoDiClassificazione: {
    label: "Piano Di Classificazione",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText: "URI del Piano di classificazione pubblicato.",
    order: 35,
  },
  riservato: {
    label: "Riservato",
    type: "select",
    options: ["Vero", "Falso"],
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: "Vero se il documento è considerato riservato, Falso se il documento non è considerato riservato.",
    order: 36,
  },
  verificaFirmaDigitale: {
    label: "Verifica Firma Digitale",
    type: "select",
    options: ["Vero", "Falso"],
    required: (documentProperties) => {
      const modalitaDiFormazione = documentProperties.modalitaDiFormazione;
      return modalitaDiFormazione === "a" || modalitaDiFormazione === "b";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Verifica della firma digitale (Vero/Falso), obbligatorio nel caso di modalità di formazione doc = a/b.",
    order: 37,
  },
  verificaSigilloElettronico: {
    label: "Verifica Sigillo Elettronico",
    type: "select",
    options: ["Vero", "Falso"],
    required: (documentProperties) => {
      const modalitaDiFormazione = documentProperties.modalitaDiFormazione;
      return modalitaDiFormazione === "a" || modalitaDiFormazione === "b";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Verifica del sigillo elettronico (Vero/Falso), obbligatorio nel caso di modalità di formazione doc = a/b.",
    order: 38,
  },
  verificaMarcaturaTemporale: {
    label: "Verifica Marcatura Temporale",
    type: "select",
    options: ["Vero", "Falso"],
    required: (documentProperties) => {
      const modalitaDiFormazione = documentProperties.modalitaDiFormazione;
      return modalitaDiFormazione === "a" || modalitaDiFormazione === "b";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Verifica della marcatura temporale (Vero/Falso), obbligatorio nel caso di modalità di formazione doc = a/b.",
    order: 39,
  },
  verificaConformitaCopie: {
    label: "Verifica Conformità Copie",
    type: "select",
    options: ["Vero", "Falso"],
    required: (documentProperties) => {
      const modalitaDiFormazione = documentProperties.modalitaDiFormazione;
      return modalitaDiFormazione === "b";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Verifica della conformità delle copie immagine su supporto informatico (Vero/Falso), obbligatorio nel caso di modalità di formazione doc = b.",
    order: 40,
  },
  idAggregazione: {
    label: "Id Aggregazione",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText: "Identificativo del fascicolo o della serie.",
    order: 41,
  },
  idDocDocumentoPrimario: {
    label: "Identificativo Documento Primario",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText: "Identificativo univoco e persistente del documento primario, nel caso in cui sia presente un documento primario.",
    order: 42,
  },
  tipoModifica: {
    label: "Tipo Modifica",
    type: "select",
    options: ["Annullamento", "Rettifica", "Integrazione", "Annotazione"],
    required: (documentProperties) => {
      const versioneDelDocumento = documentProperties.versioneDelDocumento;
      const versione = parseInt(versioneDelDocumento, 10);
      return !isNaN(versione) && versione > 1;
    },
    readOnly: false,
    visible: () => true,
    helperText: (
      <>
        <ul>
          <li>Annullamento</li>
          <li>Rettifica</li>
          <li>Integrazione</li>
          <li>Annotazione</li>
        </ul>
        Da valorizzare nel caso di versione maggiore di 1 o in caso di annullamento.
      </>
    ),
    order: 43,
  },
  soggettoAutoreDellaModifica: {
    label: "Soggetto Autore Della Modifica",
    type: "text",
    required: (documentProperties) => {
      const versioneDelDocumento = documentProperties.versioneDelDocumento;
      const tipoModifica = documentProperties.tipoModifica;
      const versione = parseInt(versioneDelDocumento, 10);
      return (!isNaN(versione) && versione > 1) || tipoModifica === "Annullamento";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Come da ruolo = Operatore definito nel metadato Soggetti, nel caso di versione maggiore di 1 o in caso di annullamento.",
    order: 44,
  },
  dataOraModifica: {
    label: "Data Modifica/Ora Modifica",
    type: "datetime",
    required: (documentProperties) => {
      const versioneDelDocumento = documentProperties.versioneDelDocumento;
      const tipoModifica = documentProperties.tipoModifica;
      const versione = parseInt(versioneDelDocumento, 10);
      return (!isNaN(versione) && versione > 1) || tipoModifica === "Annullamento";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Data e ora della modifica, nel caso di versione maggiore di 1 o in caso di annullamento.",
    order: 45,
  },
  idDocVersionePrecedente: {
    label: "IdDoc Versione Precedente",
    type: "text",
    required: (documentProperties) => {
      const versioneDelDocumento = documentProperties.versioneDelDocumento;
      const tipoModifica = documentProperties.tipoModifica;
      const versione = parseInt(versioneDelDocumento, 10);
      return (!isNaN(versione) && versione > 1) || tipoModifica === "Annullamento";
    },
    readOnly: false,
    visible: () => true,
    helperText: "Identificativo documento versione precedente, nel caso di versione maggiore di 1 o in caso di annullamento.",
    order: 46,
  },
  tempoDiConservazione: {
    label: "Tempo Di Conservazione",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText:
      "Tempo di conservazione del documento desunto dal Piano di conservazione integrato con il Piano di classificazione (ove presenti) o prescritto dalla normativa salvo contenzioso. In generale il tempo di conservazione a livello di singolo documento deve essere indicato solo qualora esso presenti un tempo di conservazione distinto da quello assegnato all'aggregazione documentale informatica a cui il documento stesso appartiene. Espresso in numero di anni, il valore 9999 indica un tempo di conservazione “Permanente.",
    order: 47,
  },
  oggetto: {
    label: "Oggetto",
    type: "text",
    required: true,
    readOnly: false,
    visible: () => true,
    helperText: "Testo libero.",
    order: 48,
  },
  paroleChiave: {
    label: "Parole Chiave",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText:
      "Da compilare facoltativamente attingendo da thesauri o da vocabolari controllati, per evitare ambiguità terminologiche e avere la possibilità di utilizzare il metadato come chiave di ricerca del documento. Il metadato è ricorsivo fino ad un massimo di 5 occorrenze.",
    order: 49,
  },
  note: {
    label: "Note",
    type: "text",
    required: false,
    readOnly: false,
    visible: () => true,
    helperText: "Eventuali indicazioni aggiuntive utili ad indicare situazioni particolari.",
    order: 50,
  },
};

const ruoloToTipoSoggettoOptions = {
  Assegnatario: ["AS"],
  Autore: ["PF", "PG", "PAI", "PAE"],
  Destinatario: ["PF", "PG", "PAI", "PAE"],
  Mittente: ["PF", "PG", "PAI", "PAE"],
  Operatore: ["PF", "SW"],
  RGD: ["PF"],
  RSP: ["PF"],
  "Soggetto che effettua la registrazione": ["PF", "PG"],
  Altro: ["PF", "PG", "PAI", "PAE"],
};

export const metadataSections = [
  {
    title: "Dettagli Documento",
    fields: [
      "nomeDelDocumento",
      "tipologiaDocumentale",
      "impronta",
      "algoritmo",
      "identificativoDocumento",
      "formatoDocumento",
      "prodottoSoftwareNome",
      "prodottoSoftwareVersione",
      "prodottoSoftwareProduttore",
      "versioneDelDocumento",
    ],
  },
  {
    title: "Formazione e Registrazione",
    fields: ["modalitaDiFormazione", "tipologiaDiFlusso", "tipoRegistro", "dataRegistrazione", "numeroDocumento", "codiceRegistro"],
  },
  {
    title: "Soggetto",
    fields: [
      "ruolo",
      "tipoSoggetto",
      "cognome",
      "nome",
      "codiceFiscale",
      "partitaIva",
      "indirizziDigitaliDiRiferimento",
      "denominazioneOrganizzazione",
      "denominazioneUfficio",
      "denominazioneAmministrazioneCodiceIPA",
      "denominazioneAmministrazioneAOOCodiceIPAAOO",
      "denominazioneAmministrazioneUORCodiceIPAUOR",
      "denominazioneAmministrazione",
      "denominazioneSistema",
    ],
  },
  {
    title: "Allegati",
    fields: ["numeroAllegati", "idDocAllegati", "descrizioneAllegati"],
  },
  {
    title: "Classificazione",
    fields: ["indiceDiClassificazione", "descrizioneClassificazione", "pianoDiClassificazione"],
  },
  {
    title: "Riservato",
    fields: ["riservato"],
  },
  {
    title: "Verifica",
    fields: ["verificaFirmaDigitale", "verificaSigilloElettronico", "verificaMarcaturaTemporale", "verificaConformitaCopie"],
  },
  {
    title: "Aggregazione Documentale",
    fields: ["idAggregazione"],
  },
  {
    title: "Identificativo Documento Primario",
    fields: ["idDocDocumentoPrimario"],
  },
  {
    title: "Tracciatura Modifiche Documento",
    fields: ["tipoModifica", "soggettoAutoreDellaModifica", "dataOraModifica", "idDocVersionePrecedente"],
  },
  {
    title: "Tempo Di Conservazione",
    fields: ["tempoDiConservazione"],
  },
  {
    title: "Chiave Descrittiva",
    fields: ["oggetto", "paroleChiave"],
  },
  {
    title: "Note",
    fields: ["note"],
  },
];
