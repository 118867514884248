// React
import React, { useState, useMemo } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";

// A ---------------------------------------------------------------------- M

const CertificationMenu = ({
  tag,
  openDocGenDrawer,
  openImageGenDrawer,
  openInfoGenDrawer,
  openConfigurationGenDrawer,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCertify = (type) => {
    handleClose();

    const actionMap = {
      PDF: openDocGenDrawer,
      Image: openImageGenDrawer,
      Note: openInfoGenDrawer,
      Configuration: openConfigurationGenDrawer,
    };

    const action = actionMap[type];
    if (action) action();
  };

  const menuItems = useMemo(() => {
    const items = [
      { label: t("certify_document"), type: "PDF" },
      { label: t("certify_image"), type: "Image" },
      { label: t("certify_note"), type: "Note" },
    ];

    if (tag && tag.targets && tag.targets.length !== 0) {
      items.push({ label: t("certify_configuration"), type: "Configuration" });
    }

    return items;
  }, [t]);

  return (
    <>
      <Button
        aria-controls="certification-menu"
        aria-haspopup="true"
        aria-label={t("new_certification")}
        variant="contained"
        startIcon={<AddIcon />}
        fullWidth
        onClick={handleClick}
        sx={{ py: { md: 1.5 } }}
      >
        {t("new_certification")}
      </Button>
      <Menu
        id="certification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : undefined,
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.type} onClick={() => handleCertify(item.type)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CertificationMenu;
