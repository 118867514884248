// React
import React, { useState, useEffect } from "react";

// Material UI Components
import { Grid } from "@mui/material";

// Components
import ClientsTable from "ui-components/CustomerManagement/ClientsTable";
import ClientFormDialog from "ui-components/CustomerManagement/ClientFormDialog";

// Firebase
import { db } from "config/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";

// Functions
import getClients from "CustomerOperations/getClients";
import postClient from "CustomerOperations/postClient";

// A ---------------------------------------------------------------------- M

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const loadClients = async () => {
      const clientsList = await getClients();
      console.log(clientsList);
      clientsList.sort(
        (client1, client2) =>
          client2.generationTimestamp - client1.generationTimestamp
      );
      console.log(clientsList);
      setClients(clientsList);
    };

    loadClients();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const clientsCollectionRef = collection(db, "clientsdata");

    const newAdditionsQueryClients = query(
      clientsCollectionRef,
      where("generationTimestamp", ">=", currentTimestampInSeconds.toString())
    );

    const unsubscribeClients = onSnapshot(
      newAdditionsQueryClients,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            loadClients();
          }
        });
      }
    );

    return () => {
      unsubscribeClients();
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateClient = async (newClientData) => {
    await postClient(newClientData);
    handleClose();
  };

  return (
    <Grid item xs={12}>
      {clients && <ClientsTable clients={clients} handleOpen={handleOpen} />}

      <ClientFormDialog
        open={open}
        handleClose={handleClose}
        handleCreateClient={handleCreateClient}
      />
    </Grid>
  );
};

export default ClientList;
