// React
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pwa-install
import { useReactPWAInstall } from "react-pwa-install";

// react-i18next
import { useTranslation } from "react-i18next";

// react-flagkit
import Flag from "react-flagkit";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material UI Icons
import LoginIcon from "@mui/icons-material/Login";

// Components
import { LoginSuccessful, LoginError, InstallBanner } from "ui-components/ORFeedbacks";

// Firebase
import { requestPermission } from "config/firebase";

// SafeTwin
import { seedFlow } from "SafeTwin/3S/seedFlow";

// UserOperations
import addFCMToken from "UserOperations/addFCMToken";

// A ---------------------------------------------------------------------- M

// Custom component for language option
const LanguageOption = ({ country, label }) => (
  <Box display="flex" alignItems="center">
    <Flag country={country} style={{ marginRight: 8 }} />
    {label}
  </Box>
);

const Signin = () => {
  const { user, setSeedFlow, signIn } = UserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const [showInstallBanner, setShowInstallBanner] = useState(supported() && !isInstalled());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const tag = location.state?.tag;

  useEffect(() => {
    handleLanguageChange("it");
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const handleClick = () => {
    pwaInstall({
      title: t("install_pwa_title"),
      description: t("install_pwa_description"),
    })
      .then(() => console.log(t("installation_success")))
      .catch(() => console.log(t("installation_error")));
  };

  const handleSignIn = async () => {
    try {
      const userCredential = await signIn(email, password);
      const token = await requestPermission();
      if (token) {
        await addFCMToken(userCredential.user.uid, token);
      }
      const isSeeded = await seedFlow(userCredential.user, password);
      setSeedFlow(isSeeded);
      setOpenSuccess(true);
      console.log("Signin successful.");
    } catch (error) {
      console.error("Signin error:", error.message);
      setPassword("");
      setOpenError(true);
    }
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate(tag ? `/${tag}` : "/");
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            mt: isMobile ? 10 : 20,
            p: isMobile ? 2 : 4,
            boxShadow: 4,
            borderRadius: 2,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold" mb="5%">
            {t("welcome")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField margin="dense" required fullWidth id="email" label={t("email")} name="email" value={email} onChange={(e) => setEmail(e.target.value)} aria-label={t("email")} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="password"
                label={t("password")}
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                aria-label={t("password")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="language-label">{t("select_language")}</InputLabel>
                <Select
                  labelId="language-label"
                  id="language-select"
                  label={t("select_language")}
                  value={language}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                  renderValue={(selected) => {
                    if (selected === "en") {
                      return <LanguageOption country="GB" label="English" />;
                    } else if (selected === "it") {
                      return <LanguageOption country="IT" label="Italiano" />;
                    }
                    return null;
                  }}
                >
                  <MenuItem value="en">
                    <LanguageOption country="GB" label="English" />
                  </MenuItem>
                  <MenuItem value="it">
                    <LanguageOption country="IT" label="Italiano" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={handleSignIn} aria-label={t("sign_in")} sx={{ py: 1.5 }} startIcon={<LoginIcon />}>
                {t("sign_in")}
              </Button>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <Typography variant="body2">
                <Link to="/reset-password">{t("reset_password")}</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {showInstallBanner && <InstallBanner showInstallBanner={showInstallBanner} handleCloseInstallBanner={() => setShowInstallBanner(false)} handleClick={handleClick} />}

      {openSuccess && <LoginSuccessful open={openSuccess} handleClose={handleCloseSuccess} />}

      {openError && <LoginError open={openError} handleClose={handleCloseError} />}
    </>
  );
};

export default Signin;
