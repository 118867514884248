// React
import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Material UI Icons
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";

// Components
import TagToolbarPublic from "ui-components/DataboxManagement/TagToolbarPublic";
import AggregatedViewer from "ui-components/DataTypeManagement/History/AggregatedViewer";
import DocViewer from "ui-components/DataTypeManagement/Doc/DocViewer";
import ImageViewer from "ui-components/DataTypeManagement/Image/ImageViewer";
import InfoViewer from "ui-components/DataTypeManagement/Info/InfoViewer";
import ConfigViewer from "ui-components/DataTypeManagement/Config/ConfigViewer";
import MpsViewer from "ui-components/DataTypeManagement/Mps/MpsViewer";

// Functions
import getTagInfo from "tag/getTagInfo";
import getTagTypes from "tag/getTagTypes";
import getTags from "tag/getTags";

// A ---------------------------------------------------------------------- M

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const PublicTag = () => {
  const { tag } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [types, setTypes] = useState([]);
  const [tagExist, setTagExist] = useState(false);
  const [tagNotExist, setTagNotExist] = useState(false);
  const [tagInformation, setTagInformation] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (tagNotExist) {
        handleTagNotExist();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [tagNotExist]);

  useEffect(() => {
    const fetchTagInfo = async () => {
      const tagInformation = await getTagInfo(tag);
      setTagInformation(tagInformation);
    };

    const getTypes = async () => {
      const types = await getTagTypes(tag);
      const allTypes = [...new Set([...types, ...types])];
      setTypes(allTypes);
    };

    const tagFlow = async () => {
      const tagIDs = await getTags();
      if (tagIDs.includes(tag)) {
        setTagExist(true);
        getTypes();
      } else {
        handleTagNotExistDrawer();
      }
    };

    const fetchData = async () => {
      await fetchTagInfo();
      await tagFlow();
      setIsLoading(false);
    };

    fetchData();
  }, [tag]);

  const handleTagNotExistDrawer = () => {
    setTagNotExist(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExist = () => {
    setTagNotExist(false);
    navigate("/signin");
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={tagNotExist}
          sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          <Box sx={{ p: "6%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SearchOffOutlinedIcon fontSize="large" sx={{ color: "red" }} />
            </Box>
            <Typography variant="h5" color="red" align="center" gutterBottom>
              Not Found
            </Typography>
            <Typography variant="body1" color="red" align="center" gutterBottom>
              DataboxID: <b>{tag}</b> doesn't exist
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}
            >
              <Button variant="contained" onClick={handleTagNotExist}>
                Close
              </Button>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <Snackbar
          open={tagNotExist}
          autoHideDuration={3000}
          onClose={handleTagNotExist}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ mt: { xs: "20%", md: "5%" } }}
        >
          <Alert
            onClose={handleTagNotExist}
            severity="error"
            sx={{ width: "100%" }}
          >
            DataboxID: <b>{tag}</b> doesn't exist.
          </Alert>
        </Snackbar>
      )}

      {tagExist && (
        <>
          <Grid item container xs={12} spacing={5}>
            {tagInformation && (
              <Grid item xs={12}>
                <TagToolbarPublic tag={tagInformation} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs={12} md={10}>
                  <Typography variant="h5" fontWeight="bold">
                    {t("certifications")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container xs={12} spacing={5}>
                {(types.includes("cfg") || types.includes("mps")) && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={1}>
                        <Grid item container justifyContent="flex-end">
                          <Tabs
                            value={value1}
                            onChange={handleChange1}
                            textColor="primary"
                            indicatorColor="primary"
                          >
                            {types
                              .filter((type) => ["cfg", "mps"].includes(type))
                              .sort((a, b) => a.localeCompare(b))
                              .map((type, index) => {
                                let label;

                                if (type === "cfg") {
                                  label = "Configurations";
                                } else if (type === "mps") {
                                  label = "IOT Stream";
                                }

                                return (
                                  <Tab
                                    key={index}
                                    label={
                                      <Typography color="primary">
                                        {label}
                                      </Typography>
                                    }
                                  />
                                );
                              })}
                          </Tabs>
                        </Grid>
                        <Grid item>
                          {types
                            .filter((type) => ["cfg", "mps"].includes(type))
                            .sort((a, b) => a.localeCompare(b))
                            .map((type, index) => (
                              <TabPanel
                                key={index}
                                value={value1}
                                index={index}
                              >
                                {type === "cfg" && (
                                  <ConfigViewer
                                    tag={{
                                      id: tagInformation.id,
                                      name: tagInformation.name,
                                    }}
                                    isTagGroupMember={false}
                                  />
                                )}
                                {type === "mps" && (
                                  <MpsViewer
                                    tag={{
                                      id: tagInformation.id,
                                      name: tagInformation.name,
                                    }}
                                    isTagGroupMember={false}
                                  />
                                )}
                              </TabPanel>
                            ))}
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                )}

                {(types.includes("doc") ||
                  types.includes("img") ||
                  types.includes("info")) && (
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={1}>
                        <Grid item container justifyContent="flex-end">
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="primary"
                          >
                            {(types.includes("doc") ||
                              types.includes("img") ||
                              types.includes("info")) && (
                              <Tab
                                label={
                                  <Typography color="primary">
                                    {t("history")}
                                  </Typography>
                                }
                              />
                            )}
                            {types
                              .filter((type) =>
                                ["doc", "img", "info"].includes(type)
                              )
                              .sort((a, b) => {
                                const order = ["doc", "img", "info"];
                                return order.indexOf(a) - order.indexOf(b);
                              })
                              .map((type, index) => {
                                let label;

                                if (type === "info") {
                                  label = t("notes");
                                } else if (type === "doc") {
                                  label = t("documents");
                                } else if (type === "img") {
                                  label = t("images");
                                }

                                return (
                                  <Tab
                                    key={index}
                                    label={
                                      <Typography color="primary">
                                        {label}
                                      </Typography>
                                    }
                                  />
                                );
                              })}
                          </Tabs>
                        </Grid>
                        <Grid item>
                          <TabPanel value={value} index={0}>
                            <AggregatedViewer
                              tag={{
                                id: tagInformation.id,
                                name: tagInformation.name,
                              }}
                              isTagGroupMember={false}
                            />
                          </TabPanel>
                          {types
                            .filter((type) =>
                              ["doc", "img", "info"].includes(type)
                            )
                            .sort((a, b) => {
                              const order = ["doc", "img", "info"];
                              return order.indexOf(a) - order.indexOf(b);
                            })
                            .map((type, index) => (
                              <TabPanel
                                key={index + 1}
                                value={value}
                                index={index + 1}
                              >
                                {type === "doc" && (
                                  <DocViewer
                                    tag={{
                                      id: tagInformation.id,
                                      name: tagInformation.name,
                                    }}
                                    isTagGroupMember={false}
                                  />
                                )}
                                {type === "img" && (
                                  <ImageViewer
                                    tag={{
                                      id: tagInformation.id,
                                      name: tagInformation.name,
                                    }}
                                    isTagGroupMember={false}
                                  />
                                )}
                                {type === "info" && (
                                  <InfoViewer
                                    tag={{
                                      id: tagInformation.id,
                                      name: tagInformation.name,
                                    }}
                                    isTagGroupMember={false}
                                  />
                                )}
                              </TabPanel>
                            ))}
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PublicTag;
