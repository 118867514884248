// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI Components
import {
  Box,
  IconButton,
  useMediaQuery,
  Dialog,
  Typography,
} from "@mui/material";

// Material UI Icons
import {
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  CloseOutlined as CloseOutlinedIcon,
} from "@mui/icons-material";

// Components
import AggregatedTable from "./AggregatedTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// RecordOperations
import fetchRecords from "RecordOperations/fetchRecords";

// Verificator
import verifySignature from "verificator/verifySignature";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AggregatedViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const typeQuery = query(
      signaturesCollectionRef,
      where("type", "not-in", ["mps", "sensor_data_aggregated", "cfg"])
    );

    const newAdditionsUnsubscribe = onSnapshot(typeQuery, (snapshot) => {
      const filteredDocs = snapshot.docs.filter(
        (doc) => doc.data().timestamp >= currentTimestampInSecondsString
      );

      if (filteredDocs.length > 0) {
        getRecords();
      }
    });

    const getRecords = async () => {
      const records = await fetchRecords(tag.id, isTagGroupMember);

      records.sort((a, b) => {
        const timestampA = a.timestamp ?? 0;
        const timestampB = b.timestamp ?? 0;
        return timestampB - timestampA;
      });

      setRecords(records);
    };

    getRecords();

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [tag, isTagGroupMember]);

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (e) {
      console.error("Error in checkVerification:", e.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    setRecord(record);
    handleOpenView();
  };

  const handleFileClick = (file, type) => {
    if (type === "doc") setSelectedFile(file);
    if (type === "img") setSelectedImage(file);
  };

  const handleCloseDocDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseImageDialog = () => {
    setSelectedImage(null);
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {records.length !== 0 && (
        <AggregatedTable
          records={records}
          checkVerification={checkVerification}
          handleView={handleView}
        />
      )}

      {record && (
        <DataDialog
          data={{ ...record, databoxName: tag.name || "N/A" }}
          open={openView}
          onClose={() => setOpenView(false)}
          handleFileClick={handleFileClick}
        />
      )}

      {verification && (
        <DatapointIntegrityInspector
          verification={verification}
          open={openVerify}
          setOpen={setOpenVerify}
        />
      )}

      {selectedFile && (
        <Dialog open={Boolean(selectedFile)} onClose={handleCloseDocDialog}>
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDocDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isMobile ? 300 : 600}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}

      {selectedImage && (
        <Dialog open={Boolean(selectedImage)} onClose={handleCloseImageDialog}>
          <IconButton
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              zIndex: 1,
              color: "red",
            }}
            onClick={handleCloseImageDialog}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img
            src={selectedImage}
            alt="img"
            style={{ width: "100%", height: "100%" }}
          />
        </Dialog>
      )}
    </>
  );
};

export default AggregatedViewer;
