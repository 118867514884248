// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// Functions
import getGroup from "GroupOperations/getGroup";
import getUserContacts from "UserOperations/getUserContacts";

// A ---------------------------------------------------------------------- M

const getViewGroups = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const viewgroupsRef = collection(tagRef, "viewgroups");
    const viewgroups = await getDocs(viewgroupsRef);

    const viewgroupsPromises = viewgroups.docs.map(async (viewgroup) => {
      const group = await getGroup(viewgroup.id);
      const creator_contacts = await getUserContacts(group.creator_uuid);
      const creator_email = creator_contacts.email;

      return {
        ...viewgroup.data(),
        ...group,
        creator_email,
        permissions: "r/w",
      };
    });

    const groups = await Promise.all(viewgroupsPromises);
    return groups;
  } catch (e) {
    console.error("Error in getViewGroups:", e.message);
    return [];
  }
};

export default getViewGroups;
