// Firebase
import { db } from "config/firebase";
import { collection, getDocs, addDoc, doc, setDoc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const postClient = async (newClientData) => {
  try {
    const defaultClientRef = collection(
      db,
      "clientsdata",
      "defaultClient",
      "agreements"
    );
    const defaultAgreementsSnap = await getDocs(defaultClientRef);

    const defaultAgreements = defaultAgreementsSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const clientsCollection = collection(db, "clientsdata");
    const newClientRef = await addDoc(clientsCollection, {
      ...newClientData,
      generationTimestamp: unixTimestampInSeconds(),
    });

    for (const agreement of defaultAgreements) {
      const newClientAgreementDocRef = doc(
        newClientRef,
        "agreements",
        agreement.id
      );
      await setDoc(newClientAgreementDocRef, {
        ...agreement,
      });
    }

    return newClientRef;
  } catch (error) {
    console.error("Error adding client:", error);
  }
};

export default postClient;
