// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

// Components
import { ResetPasswordDialog } from "ui-components/ORFeedbacks";

// A ---------------------------------------------------------------------- M

const ResetPassword = () => {
  const { resetPassword } = UserAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [openReset, setOpenReset] = useState(false);

  const handleReset = async () => {
    await resetPassword(email);
    setOpenReset(true);
  };

  const handleCloseReset = () => {
    setOpenReset(false);
    navigate("/signin");
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            mt: isMobile ? 10 : 20,
            p: isMobile ? 2 : 4,
            boxShadow: 4,
            borderRadius: 2,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant={isMobile ? "h6" : "h5"}
            fontWeight="bold"
            mb="5%"
            align="center"
          >
            {t("reset_password")}
          </Typography>
          <Typography mb="5%">{t("reset_password_text")}</Typography>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="email"
                label={t("email")}
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleReset}
                disabled={!email}
                sx={{ py: 1.5 }}
              >
                {t("reset_password")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {openReset && (
        <ResetPasswordDialog open={openReset} handleClose={handleCloseReset} />
      )}
    </>
  );
};

export default ResetPassword;
