// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// UserOperations
import getUserContacts from "UserOperations/getUserContacts";

// DatakeyOperations
import getDataKeyData from "datakey/getDataKeyData";

// A ---------------------------------------------------------------------- M

const fetchSignature = async (txid, isPublic) => {
  try {
    const sigRef = doc(db, "recordsdata", txid);
    const sigDoc = await getDoc(sigRef);

    if (!sigDoc.exists()) {
      console.error(`No document found with ID: ${txid}`);
      return null;
    }

    const signatureData = sigDoc.data();
    const creator_uuid = signatureData.creator_uuid;

    let creator_source;

    if (creator_uuid) {
      try {
        const creatorContacts = await getUserContacts(creator_uuid);
        if (creatorContacts.email) {
          creator_source = creatorContacts.email;
        } else {
          const keyData = await getDataKeyData(creator_uuid);
          creator_source = keyData.name || "N/A";
        }
      } catch (error) {
        console.error("Unable to fetch creator:", error);
      }
    } else {
      creator_source = "N/A";
    }

    const signatureOutput = {
      id: sigDoc.id,
      creator_email: creator_source,
      public: isPublic,
      ...signatureData,
    };

    return signatureOutput;
  } catch (error) {
    console.error("Error in fetchSignature:", error);
    return null;
  }
};

export default fetchSignature;
