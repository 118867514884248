// React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

// Components
import { MemberRemoveConfirm } from "ui-components/ORFeedbacks";

// Functions
import removeMemberFromGroup from "GroupOperations/removeMemberFromGroup";

// A ---------------------------------------------------------------------- M

const MembersTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "email", label: t("email") },
      { id: "role", label: t("role") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const MembersTableToolbar = ({ creator_uuid, setOpen }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={10}>
        <Typography variant="h6" fontWeight="bold">
          {t("members")}
        </Typography>
      </Grid>
      {user.uid === creator_uuid && (
        <Grid item container xs={12} md={2} justifyContent="flex-end">
          <Button
            variant="contained"
            ullWidth
            sx={{ height: "100%" }}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            {t("add_member")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MembersTable = ({
  group,
  members,
  setOpen,
  handleSuccessfulRemoved,
  handleErrorRemoved,
}) => {
  console.log(group);
  console.log(members);
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMember, setSelectedMember] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [showRemove, setShowRemove] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowRemove = (memberUID, groupID) => {
    setShowRemove(true);
    setSelectedMember(memberUID);
    setSelectedGroup(groupID);
  };

  const handleRemoveMember = async () => {
    try {
      await removeMemberFromGroup(selectedMember, selectedGroup);
      setShowRemove(false);
      handleSuccessfulRemoved();
    } catch (error) {
      handleErrorRemoved();
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  return (
    <>
      <Box>
        <MembersTableToolbar
          creator_uuid={group.creator_uuid}
          setOpen={setOpen}
        />
        <br />
        <Divider />
        <TableContainer>
          <Table>
            <MembersTableHead />
            <TableBody>
              {(rowsPerPage > 0
                ? members.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : members
              ).map((member) => {
                return (
                  <TableRow
                    key={member.uid}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell align="left">{member.email || "N/A"}</TableCell>
                    <TableCell align="left">
                      {member.uid === group.creator_uuid
                        ? t("admin")
                        : t("member")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      {user.uid === group.creator_uuid &&
                      member.uid !== group.creator_uuid ? (
                        <Tooltip title={t("remove")} placement="top">
                          <IconButton
                            sx={{ height: "100%", color: "red" }}
                            onClick={() =>
                              handleShowRemove(member.uid, group.id)
                            }
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={members.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) =>
                      t("displayed_rows", {
                        from,
                        to,
                        count: count !== -1 ? count : `more than ${to}`,
                      })
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TablePaginationActions
                count={members.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Box>
          )}
        </TableContainer>
      </Box>

      <MemberRemoveConfirm
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        handleRemoveMember={handleRemoveMember}
      />
    </>
  );
};

MembersTable.propTypes = {
  group: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  setOpen: PropTypes.array.isRequired,
  handleSuccessfulRemoved: PropTypes.array.isRequired,
  handleErrorRemoved: PropTypes.array.isRequired,
};

export default MembersTable;
